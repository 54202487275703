// This is an empty scss file for your custom styles - Please add your super duper awesome mega design styles here
//
html {
	letter-spacing: -1px;
}
h1 {
	font-size: 60px;
	color: $blue;
	padding: 15px;
	font-family: "Engagement", cursive;
}
a {
	text-decoration: none !important;
	&:hover {
		text-decoration: none !important;
	}
}

marquee {
	color: white;
	font-family: "Josefin Sans", sans-serif !important;
	font-size: 20px;
}

// Homepage 
.elementor-7772 .elementor-posts--align-center .elementor-post {
	background-color: #ffffff;
	color: #666666;
	transition: all 750ms;
	&:hover {
		background-color: $blue;
		color: white;
	}
}
.elementor-7772 .elementor-posts .elementor-post__card .elementor-post__title {
   	border-bottom: 1px solid $blue;
   	padding-bottom: 20px;
}
.home {
	.add-to-cart-container {
    	display: block;
	}
}
.elementor-posts .elementor-post__card .elementor-post__thumbnail {
	background: transparent url('https://ascomabe.com/apprendsmoiautrement/wp-content/uploads/2019/08/right-arrow-circular-button.png') center center no-repeat;
	img {
	    opacity: 1;
	    transition: all 750ms;
	    &:hover {
	    	opacity: 0.25;
		}
	}
}

// Woocommerce 
.woocommerce-message {
    border-top-color: $primary !important;
    font-family: "Josefin Sans", sans-serif !important;
}
.woocommerce-form-coupon-toggle {
	border-top-color: $blue;
	font-family: "Josefin Sans", sans-serif !important;
}
.woocommerce-info {
	border-top-color: $blue;
	font-family: "Josefin Sans", sans-serif !important;
}
.woocommerce-info::before {
	color: $blue;
}

// Product page
.woocommerce-product-details__short-description p:nth-child(2) {
	display: none;
}
table.variations select {
	border: 1px solid $blue;
}
.woocommerce div.product p.price, .woocommerce div.product span.price {
	color: $blue;
	font-family: "Josefin Sans", sans-serif;
	font-weight: 400;
}
.woocommerce div.product form.cart .reset_variations {
	color: $blue !important;
}
//single Page product
.elementor-widget-container p span {
	font-family: "Josefin Sans", sans-serif !important;	
}
.elementor-widget-container h2 span {
	font-family: "Josefin Sans", sans-serif !important;	
}
.woocommerce div.product.elementor .quantity .qty {
    padding: 15px 0 15px 15px;
}
.woocommerce div.product.elementor .quantity+.button {
	margin-left: 0;
}
p.in-stock {
	color: $blue !important;
}
// Product page Archive
.woocommerce .woocommerce-result-count {
	font-family: "Josefin Sans", sans-serif;
	padding-left: 30px;
}
.woocommerce .woocommerce-ordering select {
	font-family: "Josefin Sans", sans-serif;
}
.woocommerce ul.products li.product a img {
    width: auto;
    height: auto;
}
.added_to_cart {
	font-family: "Josefin Sans", sans-serif;	
	text-align: center;
    width: 100%;
}
.elementor-14297 .elementor-element.elementor-element-ef4be39.elementor-wc-products ul.products li.product .woocommerce-loop-product__title, .elementor-14297 .elementor-element.elementor-element-ef4be39.elementor-wc-products ul.products li.product .woocommerce-loop-category__title {
	height: 70px;
}
// Search product
.elementor-element-3e1642a {
	.elementor-widget-container {
		h5 {
			font-family: "Josefin Sans", sans-serif !important;
    		font-weight: 700;
    		color: #ffffff;
    		background-color: $blue;
    		padding: 10px;
		}
		input {
			font-family: "Josefin Sans", sans-serif !important;
		    padding: 5px 10px;
		    width: 100%;
		    margin: 0 0 10px 0;
		    border: 1px solid #999;
		}
		button {
		    width: 50%;
		    text-align: center;
		    padding: 5px 20px;
		    border: 1px solid $blue;
		    background-color: #fff;
		    color: $blue;
		    margin: 0;
		    font-family: "Josefin Sans", sans-serif !important;
			transition: all 750ms;
			&:hover {
    				background-color: $blue;
    				color: #ffffff;
    			}		    
		}
	}
}
// Catégories
.elementor-element-46834f1 {
	.elementor-widget-container {
		h5 {
			font-family: "Josefin Sans", sans-serif !important;
    		font-weight: 700;
    		color: #ffffff;
    		background-color: $blue;
    		padding: 10px;
		}
		ul {
			padding: 0;
			li.cat-item {
				list-style: none;
				padding: 0 0 0 10px;
				a {
					font-family: "Josefin Sans", sans-serif;
					color: #7a7a7a;
					&.cat-item-106 {
						display: none;
					}
				}
				&.cat-item-681 { display: none !important; }
			}
		}
	}
}

// Matière
.elementor-element-de195af {
	.elementor-widget-container {
		h5 {
			font-family: "Josefin Sans", sans-serif !important;
    		font-weight: 700;
    		color: #ffffff;
    		background-color: $blue;
    		padding: 10px;
		}
		ul {
			padding: 0; 
			li.wc-layered-nav-term {
				list-style: none;
				padding: 0 0 0 10px;
				a {
					font-family: "Josefin Sans", sans-serif;
					color: #7a7a7a;
					.count {
						color: $blue;
					}
				}
			}
		}
	} 
}
.elementor-element-5548f26 {
	.elementor-widget-container {
		h5 {
			font-family: "Josefin Sans", sans-serif !important;
    		font-weight: 700;
    		color: #ffffff;
    		background-color: $blue;
    		padding: 10px;
		}
		ul {
			padding: 0; 
			li.wc-layered-nav-term {
				list-style: none;
				padding: 0 0 0 10px;
				a {
					font-family: "Josefin Sans", sans-serif;
					color: #7a7a7a;
					.count {
						color: $blue;
					}
				}
			}
		}
	}
}
.product .count {
    background-color: inherit;
    color: $blue;
}

// Panier
.elementor-element-885da66 {
	.elementor-widget-container {
		h5 {
			font-family: "Josefin Sans", sans-serif !important;
    		font-weight: 700;
    		color: #ffffff;
    		background-color: $blue;
    		padding: 10px;
		}
		ul {
			padding: 0;
			li.mini_cart_item {
				list-style: none;
				padding: 0 0 0 10px;
				font-family: "Josefin Sans", sans-serif;
				color: #7a7a7a;
				margin: 10px 0;
				a {
					font-family: "Josefin Sans", sans-serif;
					color: $blue;
					font-weight: 400;
					&.remove {
						display: none;
					}
				}
			}
		}
		.woocommerce-mini-cart__total {
		    border-top: 1px solid $blue;
		    padding: 10px 0 10px 10px;
		    margin: 10px 0;
		    float: right;
		    width: 100%;
		    border-bottom: 1px solid $blue;
		    font-family: "Josefin Sans", sans-serif;
		}
		p.woocommerce-mini-cart__buttons {
			a {
				width: 50%;
    			text-align: center;
    			padding: 10px 20px;
    			border: 1px solid $blue;
    			background-color: #ffffff;
    			color: $blue;
   			    margin: 0 0 0 10px;
   			    font-family: "Josefin Sans", sans-serif;
    			transition: all 750ms;
    			&:hover {
    				background-color: $blue;
    				color: #ffffff;
    			}
			}
		}
		a.checkout { 
			display: none;
		 }
	}
}
.elementor-menu-cart__footer-buttons a span.elementor-button-text {
	font-size: 30px;
	font-family: "Josefin Sans", sans-serif !important;
}
// Button " add product "
.btn-outline-primary, .wpcf7 input[type=submit] {
    background-color: $blue;
    color: #ffffff; 
    border-color: $blue;
    font-family: "Josefin Sans", sans-serif !important;
    font-weight: 700;
    border-radius: 0;
}

.btn-outline-primary:hover, .wpcf7 input:hover[type=submit] {
    color: #fff;
    background-color: $blue;
    border-color: $blue;
    font-family: "Josefin Sans", sans-serif !important;
    font-weight: 700;
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle, .wpcf7 .show>input.dropdown-toggle[type=submit], .wpcf7 input:not(:disabled):not(.disabled).active[type=submit], .wpcf7 input:not(:disabled):not(.disabled):active[type=submit] {
    color: #fff;
    background-color: $blue;
    border-color: $blue;
    font-family: "Josefin Sans", sans-serif !important;
    font-weight: 700;
}
a.add_to_cart_button {
    font-family: "Josefin Sans", sans-serif !important;
}
.elementor-widget-woocommerce-archive-products.elementor-wc-products ul.products li.product .button {
	font-family: "Josefin Sans", sans-serif !important;
}

.elementor-14345 .elementor-element.elementor-element-198cf050 .elementor-post__card .elementor-post__badge {
    background-color: #61ce70 !important;
    color: #ffffff;
}

// Commentaire
.comments-area {
	font-family: "Josefin Sans", sans-serif;
	.comment-content {
		font-weight: 300;
	}	
}

// Widget Catégories
.elementor-widget-wp-widget-categories {
	h5 {
		display: none;
	} 
	ul {
		padding: 0;
		li.cat-item {
			list-style: none;
			padding: 0;
			a {
				font-family: "Josefin Sans", sans-serif;
			}
		}
	}
}

// Widget Derniers commentaires
.elementor-widget-wp-widget-recent-comments {
	h5 {
		display: none;
	} 
	ul {
		padding: 0;
		li.recentcomments {
			list-style: none;
			padding: 0 0 10px 2px;
			font-family: "Josefin Sans", sans-serif;
			a {
				font-family: "Josefin Sans", sans-serif;
			}
		}
	}
}

// Livre d'or
#gwolle_gb {
    font-family: "Josefin Sans", sans-serif;
    #gwolle_gb_write_button input {
    	background-color: $blue;
    	color: #ffffff;
    }
    #gwolle-gb-total {
    	display: none;
    }
    #gwolle_gb_new_entry {
    	margin-bottom: 30px;
    }
    .gwolle_gb_notice {
    	font-weight: 300;
  	    font-style: italic;
    }
    .gwolle_gb_form_ajax {
    	h3 {
    		margin-bottom: 15px;
    	}
    }
    .btn-primary {
	    color: #fff;
	    background-color: $blue;
	    border-color: $blue;
	}
    article {
    	padding: 15px 0;
    	.gb-author-info {
    		.gb-author-name {
    			color: $blue;
    		}
    	}
    	.gb-entry-content {
    		padding: 5px 10px;
    		font-weight: 300;
    	}
    	#gb-admin_reply_content {
    		background-color: #f4f4f4;
    	}
    	.gb-admin_reply_content, .gb-admin-reply-content {
    		font-weight: 300 !important;
    	}
    }
}

// Search page
body.search {
	h1 {
		font-family: "Fredoka One", Sans-serif;
    	font-size: 60px;
    	color: $blue;
	}
	article {
		margin: 50px 0;
		h2 {
			font-family: "Josefin Sans", sans-serif;
			a {
				text-decoration: none !important;
			}
		}
		.entry-meta {
			font-family: "Josefin Sans", sans-serif;
		}
		.entry-summary {
			font-family: "Josefin Sans", sans-serif;
			font-weight: 300;
			font-size: 16px;
		}
		.entry-footer {
			font-family: "Josefin Sans", sans-serif;
			.edit-link {
				display: none;
			}
			a.edit-link { display: none; }
		}
	}
}

.page-id-41 {
	font-family: "Josefin Sans", sans-serif;
	.woocommerce #content table.cart td.actions .input-text, .woocommerce table.cart td.actions .input-text, .woocommerce-page #content table.cart td.actions .input-text, .woocommerce-page table.cart td.actions .input-text {
		width: 160px !important;
	}
	button {
		background-color: $blue;
		color: white;
	}
	a {
		color: $blue;
	}
	.woocommerce #respond input#submit.alt, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt {
		background-color: $blue !important;
	}
}
.page-id-42 {
	font-family: "Josefin Sans", sans-serif;
	.woocommerce #content table.cart td.actions .input-text, .woocommerce table.cart td.actions .input-text, .woocommerce-page #content table.cart td.actions .input-text, .woocommerce-page table.cart td.actions .input-text {
		width: 160px !important;
	}
	input {
		border-radius: 0px !important;
	    padding: 15px 10px;
	}
	a {
		color: $blue;
	}
	.woocommerce form .form-row .input-checkbox {
	    display: inline;
	    margin: 8px 8px 0 -21px;
	    text-align: center;
	    vertical-align: middle;
	    border: 1px solid black;
	    opacity: 1;
	}
	select{
	    -webkit-appearance: none;
	    -moz-appearance: none;
	    appearance: none;
	    padding: 10px;
	    border-color: $blue !important;
	    border: 1px solid $blue !important;
	}
	button {
		background-color: $blue;
		color: white;
	}
	.woocommerce table.shop_table tbody th, .woocommerce table.shop_table tfoot td, .woocommerce table.shop_table tfoot th {
	    font-weight: 400;
	    border-top: 1px solid rgba(0,0,0,.1);
	}
	.woocommerce #respond input#submit.alt, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt {
		background-color: $blue !important;
	}
	#order_review_heading {
		margin-top: 50px;
	}
	#add_payment_method #payment div.payment_box .form-row, .woocommerce-cart #payment div.payment_box .form-row, .woocommerce-checkout #payment div.payment_box .form-row {
	    margin: 0 0 1em;
	    padding: 0;
	}
	.woocommerce-checkout #payment div.payment_box .form-row label.wc-stripe-new-payment-method { padding: 0; }
	.woocommerce form .form-row label.wc-stripe-new-payment-method {
	    padding: 0;
	}	
	.woocommerce form .form-row label {
	    padding: 20px 10px 0 10px;
	}
	.wc-stripe-elements-field, .wc-stripe-iban-element-field {
	    border: 1px solid #ddd;
	    margin: 5px 0;
	    padding: 15px;
	    background-color: #fff;
	    outline: 0;
	    width: 250px;
	}
	.woocommerce .col2-set .col-1, .woocommerce-page .col2-set .col-1 {
	    float: left;
	    width: 100%;
	    max-width: 100%;
	    margin: 50px 0;
	}
	.woocommerce .col2-set .col-2, .woocommerce-page .col2-set .col-2 {
	    float: left;
	    width: 100%;
	    max-width: 100%;
	    margin: 50px 0;
	}	
}
.page-id-43 {
	font-family: "Josefin Sans", sans-serif;
	.woocommerce #respond input#submit.alt, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt {
	    background-color: $primary;
	    color: #fff;
	    -webkit-font-smoothing: antialiased;
	}
	.wc-stripe-elements-field, .wc-stripe-iban-element-field {
	    border: 1px solid #ddd;
	    margin: 5px 0;
	    padding: 15px;
	    background-color: #fff;
	    outline: 0;
	    width: 250px;
	}
}

.form-control:focus {
	box-shadow: none !important;
}
.woocommerce form .form-row input.input-text, .woocommerce form .form-row textarea {
    padding: 15px 10px;
    border-radius: 0;
}

.term-74 {
	.elementor-element.elementor-products-grid ul.products li.product a.woocommerce-loop-product__link { width: 250px; }
}

// back to top
#button {
  display: inline-block;
  background-color: $primary;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 90px;
  right: 20px;
  transition: background-color .3s, 
    opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}
#button::after {
  content: "\f077";
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  font-size: 2em;
  line-height: 50px;
  color: #fff;
}
#button:hover {
  cursor: pointer;
  background-color: #333;
}
#button:active {
  background-color: #555;
}
#button.show {
  opacity: 1;
  visibility: visible;
}

@media all and (max-width: 1024px) {
	.woocommerce ul.products li.product a img {
	    width: 100%;
	    height: auto;
	    overflow: hidden;
	}
	.elementor-posts .elementor-post__card .elementor-post__title {
	    background-color: #e03c8d;
	    padding-bottom: 20px;
	    background-color: #e03c8d !important;
	    padding: 10px 0;
	    height: 70px;
	    border: none !important;
	    width: 500px;
	    display: table-cell;
	    vertical-align: middle;
	    border: none !important;
	}
}

// Gravity form
.elementor-element-bcaafa2 {
	font-family: "Josefin Sans", sans-serif;
	color: #7a7a7a;
	input[type=submit] {
	    background-color: #1bb7d8;
	    color: #ffffff;
	    border: none; 
	    padding: 10px 15px;
	}
}
